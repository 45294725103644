import React from "react"
import Viewport from "../components/common/viewport"
import styled from "@emotion/styled"
import Section from "../components/common/section"
import { TRANSLATIONS } from "../utils/translations"

const StyledSection = styled(Section)`
  display: inline-flex;
  align-items: center;
`

const Number = styled.span`
  font-size: 28rem;
  font-family: "darker_grotesquebold", sans-serif;
  line-height: 28rem;
`

const Message = styled.p`
  color: #fff;
  font-size: 4rem;
  background-color: #000;
  padding: 1rem 3rem;
`

const NotFoundPage = () => {
  return (
    <Viewport>
      <StyledSection>
        <Number>404</Number>
        <Message>
          {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].pageNotFound}
        </Message>
      </StyledSection>
    </Viewport>
  )
}

export default NotFoundPage
